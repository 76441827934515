import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { push } from 'ionicons/icons';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FirebasePushNotificationService {
  private readonly http = inject(HttpClient);
  BE_API_BASE_URL = environment.BE_API_BASE_URL;

  updateFCMToken(token: string) {
    this.http
      .put(`${this.BE_API_BASE_URL}/users/fcm-token`, { fcmToken: token })
      .subscribe();
  }
  public initPush() {
    if (Capacitor.getPlatform() !== 'web') {
      this.registerNotifications();
    }
  }
  registerNotifications = async () => {
    await this.addListeners();
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      return;
    }

    await PushNotifications.register();
  };

  addListeners = async () => {
    await PushNotifications.addListener('registration', (token) => {
      this.updateFCMToken(token.value);
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('push notification Registration error: ', err.error);
    });
  };

  async unregster() {
    await PushNotifications.unregister();
  }
}
